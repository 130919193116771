<template>
  <div class="custom-table-content max-width-150px">
    <v-chip
      v-if="!lodash.isEmpty(data.added_by) || !lodash.isEmpty(data.updated_by)"
      class="mx-2 mb-1 custom-grey-border"
      color="cyan darken-1"
      text-color="white"
      small
      label
    >
      <p class="m-0 custom-nowrap-ellipsis">
        <slot name="display_name"></slot>
      </p>
    </v-chip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-if="data.created_at || data.modified_at"
          class="mx-2 mb-0 custom-grey-border d-table-caption"
          color="cyan darken-1"
          text-color="white"
          v-bind="attrs"
          v-on="on"
          small
          label
        >
          <p class="m-0 custom-nowrap-ellipsis">
            <slot name="date_time"></slot>
          </p>
        </v-chip>
      </template>
      <p class="m-0 custom-nowrap-ellipsis">
        <slot name="format_date_time"></slot>
      </p>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          added_by: null,
          created_at: null,
          updated_by: null,
          modified_at: null,
        };
      },
    },
  },
};
</script>
